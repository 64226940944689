@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Prociono&family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');
body{font-family: "Poppins", sans-serif !important;}
h1, h2, h3, h4, h5, h6{ font-family: "Ubuntu", sans-serif !important;}
p{font-family: "Poppins", sans-serif !important;}
.bg-darked{ background-color: rgba(32, 36, 41, 0.94) !important; z-index:9;} 
.bg-darked a{ color: #fff !important;}
.bg-darked a:hover{ color: #ED467B;}

.nav-link{ padding:0.45rem 1.5rem !important; cursor: pointer;}
.fsize4{ font-size: 4rem;}
.bg-home-section{ position: relative; padding: 8rem 0; background-image: url(img/slide02.webp); background-repeat: no-repeat; background-position: center; background-size: cover; }
.bg-home-section:before{ background: linear-gradient(to top, rgba(233, 69, 121, 0.3) 0%, #202429 100%); content: '';position: absolute;top: 0;height: 100%;width: 100%;}
.services svg{ font-size: 2rem; padding-top:1.4rem; color:#fff !important;}
.text-pink{ color: #ED467B !important;}
.btn-pink{border:1px solid #ED467B !important; background-color:#ED467B !important;color: #fff !important;}
.btn-pink:hover{ background-color:#ED467B !important; color: #fff !important; border:1px solid #ED467B !important;} 
.services span{ background:#ED467B; width: 80px; height: 80px; margin: 10px auto; display: block; border-radius: 50px;}
.p-r { position: relative; z-index: 1;}
.navbar-brand{ letter-spacing: 3px;}
.border-btm{ width: 40px; height: 2px; background:#ED467B; display: block; }
.contact-list li{ margin-bottom: 10px;}
.border-design{ position: absolute; top: 0; left: 0;}
.pagenotfound{ padding: 10rem 0;}
.navbar-nav a:hover{ background:#ED467B; border-radius:50px; }
.navbar-nav a.active{background:#ED467B; border-radius:50px; }



.line-1{
    position: relative;
    top: 50%;  
    width: 20em;
    margin: 0 auto;
    border-right: 2px solid rgba(255,255,255,.75);
    font-size: 180%;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    transform: translateY(-50%);    
}

/* Animation */
.anim-typewriter{
  animation: typewriter 4s steps(30) 1s 1 normal both,
             blinkTextCursor 500ms steps(30) infinite normal;
}
@keyframes typewriter{
  from{width: 0;}
  to{width: 20em;} 
}
@keyframes blinkTextCursor{
  from{border-right-color: rgba(255,255,255,.75);}
  to{border-right-color: transparent;}
}

@media only screen and (max-width: 640px) {
  .line-1{ font-size: 100%;}
  .fsize4{ font-size: 3rem;}
}

button:focus:not(:focus-visible){
  box-shadow: 0px 0px 0px !important;
}